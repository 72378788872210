<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400"
      :class="{ 'secondBox-borrom': $route.path === '/question' || $route.path === '/protocol' }">
      <div class="logo">
        <!-- <img
          :src="require('@/page/bienestarricotecnologia/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">ALL FINTECH COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">เงินกู้</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">เกี่ยวกับเรา</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">ศูนย์ช่วยเหลือ</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">ข้อตกลงความเป็นส่วนตัว</span>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 130px"></div> -->
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 90px; */
  padding: 0 15px;
  background-color: #FFFFFF;
  /* box-shadow: 0px 3px 12.2px 0 rgba(65, 64, 64, 0.1); */
  /* background-image: url("@/page/bienestarricotecnologia/components/img/NavBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  /* box-shadow: 0px 4px 9.4px 0px rgba(0, 0, 0, 0.1); */
}

.secondBox-borrom {
  /* box-shadow: 0px 1.5px 0px rgba(54, 53, 56, 1); */
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 30px;
}

.title {
  width: 500px;
  font-size: 18px;
  font-family: DM Sans-Bold;
  font-weight: 600;
  color: #333333;
  /* line-height: 23px; */
  padding-left: 17px;
  /* text-transform: uppercase; */
  height: 24px;
  line-height: 24px;
  /* height: 90px;
  line-height: 90px; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  /* font-weight: 400; */
  color: #333333;
  /* opacity: 0.8; */
  /* line-height: 29px; */
  /* text-transform: uppercase; */
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
  height: 100%
}

.nav-links li {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-left: 4px;
  padding-right: 4px;
  padding: 6px;
  height: 39px;
  /* line-height: 90px; */
}

.Li1 {
  width: 100px;
}

.Li2 {
  width: 140px;
}

.Li3 {
  width: 160px;
}

.Li4 {
  width: 240px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.active-link {
  border-color: #FBC85B;
  background-color: #FBC85B;
  border-radius: 26px;
  display: flex;
  align-items: center;
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-family: Inter-Extra Bold;
  font-weight: bold;
  color: #100201;
  /* opacity: 1; */
}

.title {
  /* border: 1px solid red; */
  /* padding-left: 30px; */
  width: 400px
}

.secondBox {
  padding: 0 130px 0 130px;
}
</style>